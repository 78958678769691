/* eslint-disable */
export default {
  init() {
    // JavaScript to be fired on all pages
    const mobileToggle = document.querySelector('.mobile-toggle');
    const mobileMenu = document.querySelector('.mobile-menu');
  
    $('.nav > .menu-item-has-children').on('mouseenter', function() {
      $('.menu-item-has-children').removeClass('open')
      $(this).addClass('open')
    })

    $('.sub-menu').on('mouseleave', function() {
      $('.menu-item-has-children').removeClass('open')
    })
    
    if(mobileToggle) {
      mobileToggle.addEventListener('click', function() {
        this.classList.toggle('open')
        $('html').toggleClass('no-scroll')
        $('.main-header').toggleClass('open')

        if(mobileMenu) {
          mobileMenu.classList.toggle('open')
        }
      })
    }

    $('.welcome-banner a').on('click', function(e) {
      e.preventDefault()
      $('.welcome-banner').addClass('hide')
      localStorage.setItem('welcomeBanner', 'shown')
    })

    const welcomeBannerStatus = localStorage.getItem('welcomeBanner')
    const welcomeBanner = document.querySelector('.welcome-banner')

    if(welcomeBanner && welcomeBannerStatus) {
      welcomeBanner.classList.add('hide')
    }

    // Video Modal

    const popUpVideo = document.querySelector('.popup-video-container')
    const popUpVideoWrapper = document.querySelector('.popup-video-inner-wrapper')
    const popUpVideoCloseBtn = document.querySelector('.popup-video-container svg')
    const videoTriggers = document.querySelectorAll('.video-container[data-video]')

    document.addEventListener('keydown', ({key}) => {
      if (key === 'Escape') {
        popUpVideo.close()
        popUpVideoWrapper.innerHTML = ""
        // let src = player.src
        // player.src = src
      }
    })

    popUpVideo.addEventListener('click', () => {
      popUpVideo.close()
      popUpVideoWrapper.innerHTML = ""
      // let src = player.src
      // player.src = src
    })

    popUpVideoCloseBtn.addEventListener('click', () => {
      popUpVideo.close()
      popUpVideoWrapper.innerHTML = ""
      // let src = player.src
      // player.src = src
    })

    if(videoTriggers !== null) {
      videoTriggers.forEach((videoTrigger) => {
        videoTrigger.addEventListener('click', () => {
          videoModalClicked(videoTrigger);
        })
      })
    }

    let iframes = document.querySelectorAll('iframe');
    if(iframes !== null) {
      iframes.forEach(function(iframe) {
        addVimeoTracking(iframe);
      });
    }

    let spotlightVideoTrigger = document.querySelector('.spotlight-video-trigger');

    if(spotlightVideoTrigger !== null) {
      spotlightVideoTrigger.addEventListener('click', () => {
        videoModalClicked(videoTrigger);
      });
    }

  function videoModalClicked(videoTrigger) {
    const video_iframe = document.createElement('iframe')
    video_iframe.setAttribute('frameborder', 'none')
    video_iframe.classList.add('fade-in', 'popup-video')
    video_iframe.src =  videoTrigger.dataset.video
    popUpVideoWrapper.append(video_iframe);
    addVimeoTracking(video_iframe);
    popUpVideo.show()
  }

  function addVimeoTracking(video_iframe) {
    let player = new Vimeo.Player(video_iframe);
    player.on('play', function() {
      //Get title via promise, and add 
      player.getVideoTitle().then(function(title) {
        dataLayer.push({
          'event' : 'video_start',
          'video_provider' : 'vimeo',
          'video_title' : title,
        });
      });
    });
    let watched_10_percent = false;
    let watched_25_percent = false;
    let watched_50_percent = false;
    let watched_75_percent = false;
    let watched_100_percent = false;
    player.on('timeupdate', (data) => {
      // console.log(data.percent);
      if(data.percent > .10 && !watched_10_percent) {
        player.getVideoTitle().then(function(title) {
          dataLayer.push({
            'event' : 'watched_10_percent',
            'video_provider' : 'vimeo',
            'video_title' : title,
          });
        });
        watched_10_percent = true;
      }

      if(data.percent > .25 && !watched_25_percent) {
        player.getVideoTitle().then(function(title) {
          dataLayer.push({
            'event' : 'watched_25_percent',
            'video_provider' : 'vimeo',
            'video_title' : title,
          });
        });
        watched_25_percent = true;
      }

      if(data.percent > .50 && !watched_50_percent) {
        player.getVideoTitle().then(function(title) {
          dataLayer.push({
            'event' : 'watched_50_percent',
            'video_provider' : 'vimeo',
            'video_title' : title,
          });
        });
        watched_50_percent = true;
      }

      if(data.percent > .75 && !watched_75_percent) {
        player.getVideoTitle().then(function(title) {
          dataLayer.push({
            'event' : 'watched_75_percent',
            'video_provider' : 'vimeo',
            'video_title' : title,
          });
        });
        watched_75_percent = true;
      }

      if(data.percent == 1 && !watched_100_percent) {
        player.getVideoTitle().then(function(title) {
          dataLayer.push({
            'event' : 'watched_100_percent',
            'video_provider' : 'vimeo',
            'video_title' : title,
          });
        });
        watched_100_percent = true;
      }
    });
  }

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
